<style scoped></style>

<template>
  <div style="margin: 30px 0;">
    <img src="/static/images/null.png" style="width: 40px;"/>
    <div>暂无数据</div>
  </div>
</template>

<script>
export default {

}
</script>
